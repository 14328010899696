<template>
  <responsive-form :form="form">
    <a-form-item label="ID">
      <a-input v-decorator="['id', { initialValue: 0 }]" :disabled="this.model != null" />
    </a-form-item>
    <a-form-item label="sysVer">
      <a-input v-decorator="['sysVer', {initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="dataVer">
      <a-input v-decorator="['dataVer', {initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="gameVer">
      <a-input v-decorator="['gameVer', {initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="gameId">
      <a-input v-decorator="['gameId', {initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="keyChipIdList">
      <a-input v-decorator="['keyChipIdList', {initialValue: '*'}]" />
    </a-form-item>
    <a-form-item label="updateUrl">
      <a-input v-decorator="['updateUrl', {initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="hash">
      <a-input v-decorator="['hash', {initialValue: ''}]" />
    </a-form-item>
  </responsive-form>
</template>

<script>
import pick from 'lodash.pick'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'

// 表单字段
const fields = ['id', 'sysVer', 'dataVer', 'gameVer', 'gameId', 'keyChipIdList', 'updateUrl', 'hash']

export default {
  components: { ResponsiveForm },
  props: {
    model: {
      type: Object,
      default: () => null
    },
    isTemplate: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
