<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="你确定要删除吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDelete(record)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>

      <modal-form
        :title="formTitle"
        :visible="formVisible"
        :loading="confirmLoading"
        @cancel="handleCancel"
        @ok="handleSave"
      >
        <imagic-update-form
          ref="createModal"
          :model="formModel"
          :is-template="true"
        />
      </modal-form>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import ModalForm from '@/components/ModalForm/ModalForm'
import { deleteImagicUpdate, getImagicUpdateList, updateImagicUpdateDetail } from '@/api/imagic'
import ImagicUpdateForm from '@/views/arcade/module/ImagicUpdateForm.vue'

export default {
  name: 'ImagicUpdate',
  components: {
    ImagicUpdateForm,
    STable,
    ModalForm
  },
  data () {
    return {
      formTitle: '',
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
      },
      loadData: parameter => {
        return getImagicUpdateList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: 'sysVer',
          dataIndex: 'sysVer'
        },
        {
          title: 'dataVer',
          dataIndex: 'dataVer'
        },
        {
          title: 'gameVer',
          dataIndex: 'gameVer'
        },
        {
          title: 'gameId',
          dataIndex: 'gameId'
        },
        {
          title: 'keyChipIdList',
          dataIndex: 'keyChipIdList'
        },
        {
          title: 'updateUrl',
          dataIndex: 'updateUrl'
        },
        {
          title: 'hash',
          dataIndex: 'hash'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.formTitle = '添加更新'
      this.formModel = null
      this.formVisible = true
      // Wait the form being created
      this.$nextTick(() => {
        this.$refs.createModal.form.resetFields()
      })
    },
  handleCancel () {
    this.formVisible = false
  },
    handleEdit (record) {
      this.formTitle = '修改更新'
      this.formVisible = true
      this.$nextTick(() => {
        this.formModel = record
      })
    },
    handleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          updateImagicUpdateDetail(values).then(() => {
            this.formVisible = false
            this.confirmLoading = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('修改成功')
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (record) {
      deleteImagicUpdate({ id: record.id }).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped>

</style>
