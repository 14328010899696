import request from '@/utils/request'
const api = {
  list: '/imagic/list',
  detail: '/imagic/detail',
  update: '/imagic/update',
  delete: '/imagic/delete'
}
export default api

export function getImagicUpdateList (parameter) {
  return request({
    url: api.list,
    method: 'get',
    params: parameter
  })
}
export function getImagicUpdateDetail (parameter) {
  return request({
    url: api.detail,
    method: 'get',
    params: parameter
  })
}
export function updateImagicUpdateDetail (data) {
  return request({
    url: api.update,
    method: 'post',
    data
  })
}
export function deleteImagicUpdate (data) {
  return request({
    url: api.delete,
    method: 'post',
    data
  })
}
